import { useMemo, useRef } from "react";
import styles from "./styles.module.scss";

const ProgressBarCircle = ({ className, configuration, currentCommission, profileCommission }) => {
  const circlesContainerRef = useRef(null);

  const startCommission = profileCommission || 15;
  const commissionThresholds = useMemo(() => {
    if (!configuration?.driverCommissions || configuration.driverCommissions.length === 0) {
      return [];
    }

    const hasInitialThreshold = configuration.driverCommissions.some(
      (threshold) => threshold.ordersCount === 0
    );

    return hasInitialThreshold
      ? configuration.driverCommissions
      : [{ ordersCount: 0, commission: startCommission }, ...configuration.driverCommissions];
  }, [configuration?.driverCommissions, currentCommission.currentCommission]);

  const maxOrders = commissionThresholds.length > 0 ? commissionThresholds[commissionThresholds.length - 1].ordersCount : 0;

  const segments = useMemo(() => {
    if (commissionThresholds.length === 0) return [];

    return commissionThresholds.map((threshold, index) => {
      const position = (index / (commissionThresholds.length - 1)) * 100;
      const isCurrent =
        currentCommission.finishedOrdersCount >= threshold.ordersCount &&
        (index === commissionThresholds.length - 1 ||
          currentCommission.finishedOrdersCount < commissionThresholds[index + 1].ordersCount);
      const isActive = currentCommission.finishedOrdersCount >= threshold.ordersCount;

      return {
        commission: threshold.commission,
        position,
        isCurrent,
        isActive,
      };
    });
  }, [commissionThresholds, currentCommission.finishedOrdersCount]);

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.circlesContainer} ref={circlesContainerRef}>
        {segments.map(({ commission, position, isCurrent, isActive }, index) => (
          <div
            key={index}
            className={styles.circleWrapper}
            style={{ left: `calc(${position}% - 15px)` }}
          >
            <div
              className={`${styles.circle} ${isCurrent
                ? `${styles.current} ${configuration.hasSkippedAutoAssignment &&
                  currentCommission.finishedOrdersCount >= configuration.driverCommissions[1].ordersCount
                  ? styles.hasSkippedAutoAssignment
                  : ''
                }`
                : isActive
                  ? styles.active
                  : styles.inactive
                }`}
              style={{
                width: isCurrent ? 44 : 34,
                height: isCurrent ? 44 : 34,
                lineHeight: `${isCurrent ? 44 : 34}px`,
              }}
            >
              <p>
                {isCurrent && configuration.hasSkippedAutoAssignment &&
                  currentCommission.finishedOrdersCount >= configuration.driverCommissions[1].ordersCount ?
                  (startCommission) : ( commission )
                }
                {isCurrent && ("%")}
              </p>
            </div>
            {index < segments.length - 1 && (() => {
              if (!circlesContainerRef.current) return null;
              const containerWidth = circlesContainerRef.current.offsetWidth;
              const nextPosition = segments[index + 1].position;
              const widthPx = ((nextPosition - position) / 100) * containerWidth - 15;
              return (
                <div
                  className={`${styles.line} ${currentCommission.finishedOrdersCount >= commissionThresholds[index + 1].ordersCount
                    ? styles.lineActive
                    : styles.lineInactive
                    }`}
                  style={{
                    left: '70%',
                    width: `${widthPx}px`,
                  }}
                ></div>
              );
            })()}
          </div>
        ))}
      </div>
    </div>
  );
};
export default ProgressBarCircle;

