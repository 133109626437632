import { memo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as Cashback } from "src/images/cashback-v2.svg";
import { ReactComponent as MoneyCard } from "src/images/money-card.svg";
import { ReactComponent as MoneyCash } from "src/images/money-cash.svg";
import { calculateCostPerKm } from "src/services/calculateCostPerKm";
import { getValuteSymbol } from "src/services/getValuteSymbol";
import SortingButton from "../../../EtherOrdersDataControl/components/SortingButton";
import EtherOrderMap from "../EtherOrderMap";
import { paymentOptions } from "src/shared/const/payment";
import styles from "./styles.module.scss";
import HighDemandColor from "src/components/HighDemandColor";
import { metersToKm } from "src/services/metersToKm";

const EtherOrderType = (props) => {
  const {
    paymentTypeId,
    priceEstimated,
    carClass,
    distance,
    compensation,
    currency,
    destinationPoints,
    distanceToOrder,
    quoteBonus,
    polylinePositions,
    highDemandColor,
    backgroundClass,
    startToFinish,
  } = props;

  const { t } = useTranslation();

  const showOnlyCompensation = Boolean(compensation && !quoteBonus);
  const currentPaymentMethod = paymentOptions[paymentTypeId];
  const isCashPayment =
    currentPaymentMethod === paymentOptions.Cash || currentPaymentMethod === paymentOptions.CashAndBonus;
  const PaymentIcon = isCashPayment ? MoneyCash : MoneyCard;

  const renderPriceAndBonuses = showOnlyCompensation ? priceEstimated : priceEstimated - quoteBonus;
  const renderCompensationAndBonuses = showOnlyCompensation ? compensation : quoteBonus + compensation;

  const renderPriceInfo = (
    <div className={styles.priceAndType}>
      <SortingButton Icon={PaymentIcon} className={styles.price} size="big">
        <span>{priceEstimated + compensation}</span>
        <span>{getValuteSymbol(currency)}</span>
      </SortingButton>
      <div className={styles.etherOrderTypeHightDemandIcon}>
        {highDemandColor && <HighDemandColor highDemandColor={highDemandColor} />}
      </div>
      <SortingButton className={`${styles.carType} ${backgroundClass && styles[backgroundClass] ? styles[backgroundClass] : styles.defaultBackground}`} size="big">
        <span>{carClass}</span>
      </SortingButton>
    </div>
  );

  const renderDistance = (id) => {
    const isStartPoint = id === 0;
    const isEndPoint = id === destinationPoints.length - 1;

    if (isStartPoint || isEndPoint) {

      return (
        <div className={styles.distanceContainer}>
          <p>
            {isStartPoint
              ? metersToKm(distanceToOrder)
              : metersToKm(startToFinish)}
          </p>
          <p>{t("km")}</p>
        </div>
      );
    }

    return null;
  };

  const renderAdditionalPriceInfo = (
    <>
      <div className={styles.destinationPointsContainer}>
      <p className={styles.priceForKm}>
          <Trans
            i18nKey="price_for_km"
            values={{
              cost: calculateCostPerKm(distance, priceEstimated),
              currency: getValuteSymbol(currency),
            }}
          />
        </p>
        <div className={styles.compensationInfo}>
          {Boolean(quoteBonus || compensation) && (
            <SortingButton Icon={PaymentIcon} className={styles.priceAndCompensation} size="small">
              <span className={styles.allPrice}>
                {priceEstimated}
                {getValuteSymbol(currency)}
              </span>

              <span className={styles.divider} />

              <span className={styles.compensation}>
                <Cashback className={styles.icon} />
                {renderCompensationAndBonuses}
                {getValuteSymbol(currency)}
              </span>
            </SortingButton>
          )}
        </div>
        <div className={styles.distanceContainer}>
          
        </div>
        <div className={styles.distanceContainer}>
          {destinationPoints.map((point) => (
            <div className={styles.distance} key={point.pointId}>
              {renderDistance(Number(point.pointId))}
            </div>
          ))}

        </div>


      </div>
    </>
  );

  return (
    <div className={styles.etherOrderType}>
      <div className={styles.headerbutton}>
        <div className={styles.container}>
          {renderPriceInfo}
        </div>
        <div className={styles.mapButton}>
          <EtherOrderMap destinationPoints={destinationPoints} polylinePositions={polylinePositions} />
        </div>
      </div>
      <div className={styles.distanceInformation}>
        {renderAdditionalPriceInfo}
      </div>
    </div>
  );
};

export default memo(EtherOrderType);
