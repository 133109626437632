export const executeWithDelay = async (asyncFunction, delay = 3000) => {
    try {
      await asyncFunction();
  
      setTimeout(() => {
        asyncFunction();
      }, delay);
    } catch (error) {
      console.error("Error in executeWithDelay:", error);
    }
  };