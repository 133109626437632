import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import SortingButton from "src/pages/EtherOrdersPage/components/EtherOrdersDataControl/components/SortingButton";
import { useTranslation } from "react-i18next";
import { getPercentageByPrice } from "src/shared/const/ordersFilterCounter";
import { ReactComponent as WarningFilter } from "src/images/warning-filter.svg";

const PriceSliderFilter = ({ minPrice, setNewOrderSearchFilter, currency, isActiveButton }) => {
    const [min, setMin] = useState(minPrice);
    const sliderRef = useRef(null);
    const [isActiveAllButton, setIsActiveAllButton] = useState(isActiveButton);
    const { t } = useTranslation();

    let decodedText = t('price_slider_filter_description', { percent: getPercentageByPrice(min) }).replace(/&lt;/g, "<");
    useEffect(() => {
        updateTrackWidth();
        decodedText = t('price_slider_filter_description', { percent: getPercentageByPrice(min) }).replace(/&lt;/g, "<");
    }, [min]);

    useEffect(() => {
        if (isActiveAllButton) {
            setNewOrderSearchFilter((prev) => ({
                ...prev,
                minPrice: null,
            }));
        } else {
            setNewOrderSearchFilter((prev) => ({
                ...prev,
                minPrice: min,
            }));
        }
    }, [isActiveAllButton]);

    const updateTrackWidth = () => {
        if (sliderRef.current) {
            const percent = ((min - 50) / (500 - 50)) * 100;
            sliderRef.current.style.setProperty("--progress-width", `${percent}%`);
        }
    };

    const handleMinChange = (e) => {
        const value = Number(e.target.value);
        setMin(value);
        localStorage.setItem("priceSliderFilter", JSON.stringify({ minPrice: value }));

        setNewOrderSearchFilter((prev) => ({
            ...prev,
            minPrice: value
        }));
    };
    return (
        <div className={styles.container}>
            <span className={`${styles.value} ${isActiveAllButton ? styles.disabled : ""}`}>{min} {currency} </span>

            <div className={styles.controlContainer}>
                <div>
                    <SortingButton
                        active={isActiveAllButton}
                        key="All"
                        onClick={() => setIsActiveAllButton(!isActiveAllButton)}
                        size="filterStyle"
                    >
                        {t("all")}
                    </SortingButton>
                </div>

                <input
                    ref={sliderRef}
                    type="range"
                    min="50"
                    max="500"
                    step="5"
                    value={min}
                    onChange={handleMinChange}
                    className={`${styles.slider} ${isActiveAllButton ? styles.disabled : ""}`}
                    disabled={isActiveAllButton}
                />
            </div>
            {
                !isActiveAllButton && min > 70 && (
                    <div
                        className={styles.WarningFilterContainer}
                        style={{
                            "--dynamic-color":
                                min > 220 ? "red" : min > 135 ? "yellow" : "var(--text-color-1)",
                        }}
                    >
                        <WarningFilter className={styles.WarningFilter} />
                        <p className={styles.description}>{decodedText}</p>
                    </div>
                )
            }
        </div >
    );
};

export default PriceSliderFilter;
