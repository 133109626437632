import React, { forwardRef } from "react";

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { t } from "i18next";

import useClickOutside from "src/hooks/useClickOutside";

import ModalNew from "src/pages/Modals/ModalNew";

import { BlackButton } from "src/shared/ui/Buttons/BlackButton";
import { BorderedButton } from "src/shared/ui/Buttons/BorderedButton";


import styles from "./styles.module.scss";

const ChangeDriverPopup = ({ onClick, onClose, showChangeDriverPopup }, ref) => {
  const alertPopupRef = useClickOutside(onClose);

  const popupInner = (
    <div className={styles.changeDriverPopup} ref={alertPopupRef}>
      <ChangeCircleIcon className={styles.changeImage} />
      <h2>{t("change_driver")}</h2>
      <p className={styles.changeDriverDescription}>{t("change_driver_description")}</p>
      <BlackButton onClick={onClick}>{t("yes")}</BlackButton>
      <BorderedButton onClick={onClose}>{t("cancellation")}</BorderedButton>
    </div>
  );

  return (
    <ModalNew
      customInnerComponent={popupInner}
      newModalContentClassName={styles.newModalContentClassName}
      newModalWrapClassName={`${styles.alertPopupWrap} ${showChangeDriverPopup ? styles.alertPopupWrapActive : ""}`}
    />
  );
};

export default forwardRef(ChangeDriverPopup);
