import { t } from "i18next";
import ChangeDriver from "../ChangeDriver";
import CancelOrderButton from "../CancelOrderButton";
import usePreOrderTimer from "../../hooks/usePreOrderTimer";

const OrderMainButtons = (props) => {
  const {
    wrapCallBack,
    paidWait,
    stage,
    isCorrectStatusId,
    checkArrivalDistance,
    clientOn,
    rejectOrderConfirm,
    completeOrd,
    onSetPaidWait,
    resumeOrderFromPause,
    paymentType,
    onChangeDriver,
    handlePayment
  } = props;

  const { formattedTime, isButtonDisabled } = usePreOrderTimer();


  return (
    <div className="bottom-nav" onClick={wrapCallBack}>
      {stage === 1 && (
        <>
          <p
            className={!isCorrectStatusId && isButtonDisabled ? "status" : "status disabled_btn"}
            onClick={isButtonDisabled ? checkArrivalDistance : null}
          >
            {formattedTime}
          </p>
          <ChangeDriver onChangeDriver={onChangeDriver} />
        </>
      )}

      {stage === 4 && (
        <>
          <p className={!isCorrectStatusId ? "status" : "status disabled_btn"} onClick={clientOn}>
            {t("performing")}
          </p>
          <CancelOrderButton onClick={rejectOrderConfirm} />
        </>
      )}

      {stage === 8 && (
        <>
          {!paidWait && (
            <p className={!isCorrectStatusId ? "status" : "status disabled_btn"} onClick={completeOrd}>
              {t("trip_completed")}
            </p>
          )}
          {!paidWait ? (
            <p className="cancel" onClick={onSetPaidWait}>
              {t("stop")}
            </p>
          ) : (
            <p className="resume" onClick={resumeOrderFromPause}>
              {t("resume_trip")}
            </p>
          )}
        </>
      )}

      {stage === 16 && (
        <p className={!isCorrectStatusId ? "status" : "status disabled_btn"} onClick={handlePayment}>
          {paymentType === 1 || paymentType === 4 ? t("complete") : t("payment_received")}
        </p>
      )}
    </div>
  );
};

export default OrderMainButtons;
