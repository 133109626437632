import styles from "./styles.module.scss";
import ProgressBarCircle from "../ProgressBarСircle";
import MobileTopBarHeight from "../MobileTopBarHeight";

const TopProgressBar = (props) => {
  const { currentCommission, configuration, profileCommission } = props;

  return (
    <div className={styles.container}>
      <MobileTopBarHeight/>
      <ProgressBarCircle
        currentCommission={currentCommission}
        configuration={configuration}
        profileCommission={profileCommission}
      />
    </div>
  );
};

export default TopProgressBar;
