import { axiosInstance } from "src/axios.config";
import { reputationApi, driverMotivationApi, orderMotivationApi } from "src/config";

import {
  fetchCurrentCommissionSuccess,
  fetchConfigurationSuccess,
  fetchDriverCommissionSuccess,
  fetchDriverRatingFailure,
  fetchDriverRatingRequest,
  fetchDriverRatingSuccess,
  fetchReputationRulesSuccess,
} from "./motivation.actions";

export const fetchDriverRating = () => {
  return async (dispatch) => {
    dispatch(fetchDriverRatingRequest());
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance({
        method: "get",
        url: `${reputationApi}/driver-rating-quality-of-service`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      dispatch(fetchDriverRatingSuccess(response.data));
    } catch (error) {
      console.log("Error", error);
      dispatch(fetchDriverRatingFailure(error.message));
    }
  };
};

export const fetchDriverCommission = () => {
  return async (dispatch) => {
    dispatch(fetchDriverRatingRequest());

    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axiosInstance({
        method: "get",
        url: `${reputationApi}/driver-commission-bonus-rule`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      dispatch(fetchDriverCommissionSuccess(response.data));
    } catch (error) {
      console.log("Error", error);
    }
  };
};

export const fetchReputationRules = () => {
  return async (dispatch) => {
    dispatch(fetchDriverRatingRequest());

    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axiosInstance({
        method: "get",
        url: `${reputationApi}/reputation-rules`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      dispatch(fetchReputationRulesSuccess(response.data));
    } catch (error) {
      console.log("Error", error);
      dispatch(fetchDriverRatingRequest());
    }
  };
};


export const fetchConfiguration = () => {
  return async (dispatch) => {
    dispatch(fetchDriverRatingRequest()); 

    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axiosInstance({
        method: "get",
        url: `${driverMotivationApi}`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      
      const sortedConfiguration = {
        ...response.data,
        driverCommissions: response.data.driverCommissions
          .map((item) => ({
            ...item,
            commission: Math.floor(item.commission), 
          }))
          .sort((a, b) => a.ordersCount - b.ordersCount),
      };
      dispatch(fetchConfigurationSuccess(sortedConfiguration));
    } catch (error) {
      console.log("Error", error);
      dispatch(fetchDriverRatingFailure(error.message));
    }
  };
};


export const fetchCurrentCommission = (cityId) => {
  return async (dispatch) => {
    dispatch(fetchDriverRatingRequest());

    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axiosInstance({
        method: "get",
        url: `${orderMotivationApi}/cities/${cityId}/driver`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      dispatch(fetchCurrentCommissionSuccess(response.data));
    } catch (error) {
      console.log("Error fetching current commission:", error);
      dispatch(fetchDriverRatingFailure(error.message));
    }
  };
};
