import { useState } from "react";
import styles from "./styles.module.scss";
import { Modal } from "src/shared/ui/Modal";
import { ReactComponent as Settings } from "src/images/settings.svg";

import AutoAcceptOptions from "../AutoAcceptOptions";
import "./styles.module.scss";

const AutoAcceptOptionsModal = (props) => {
  const { orderSearchFilter, updateOrderSearchFilter, getDriverInfo, currency } = props;

  const [openModal, setOpenModal] = useState(false);

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <button onClick={onOpenModal} className={styles.button}>
        <Settings />
      </button>
      <Modal isOpen={openModal} noPadding onClose={onCloseModal}>
        <AutoAcceptOptions
          onCloseModal={onCloseModal}
          orderSearchFilter={orderSearchFilter}
          updateOrderSearchFilter={updateOrderSearchFilter}
          getDriverInfo = {getDriverInfo}
          currency = {currency}
        />
      </Modal>
    </>
  );
};

export default AutoAcceptOptionsModal;
