import { memo } from "react";
import { ReactComponent as PointIcon } from "src/images/point.svg";
import { ReactComponent as WaypointIcon } from "src/images/waypoint.svg";
import { ReactComponent as FinishPointIcon } from "src/images/finish-point.svg";
import { classNames } from "src/shared/lib/classNames/classNames";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const EtherOrderAddress = ({ destinationPoints }) => {
  const { t } = useTranslation();

  const renderPointIcon = (id) => {
    const isLastPoint = id === destinationPoints.length - 1;

    if (isLastPoint) {
      return <FinishPointIcon className={classNames(styles.icon, {}, [])} />;
    }

    if (id === 0) {
      return <PointIcon />;
    }

    return <WaypointIcon className={classNames(styles.icon, {}, [])} />;
  };

  return (
    <div className={styles.EtherOrderAddress}>
      <ul className={styles.listOfOrders}>
        {destinationPoints.map((point) => (
          <li className={styles.orderListItem} key={point.pointId}>
            <div className={styles.orderDetails}>
              <div className={styles.imageContainer}>{renderPointIcon(Number(point.pointId))}</div>
              <div className={styles.addressText}>
                <p className={styles.mainAddress}>{point.mainText || point.name}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(EtherOrderAddress);
