const priceToPercentage = [ 
    { price: 70, percentage: "2%" },    
    { price: 80, percentage: "5%" },    
    { price: 90, percentage: "7%" }, 
    { price: 100, percentage: "11%" }, 
    { price: 110, percentage: "21%" }, 
    { price: 120, percentage: "29%" }, 
    { price: 130, percentage: "37%" },  
    { price: 140, percentage: "44%" }, 
    { price: 150, percentage: "52%" },  
    { price: 160, percentage: "57%" }, 
    { price: 170, percentage: "63%" },  
    { price: 180, percentage: "67%" },  
    { price: 190, percentage: "72%" }, 
    { price: 200, percentage: "76%" },  
    { price: 210, percentage: "79%" },  
    { price: 220, percentage: "82%" },  
    { price: 230, percentage: "84%" },  
    { price: 240, percentage: "86%" }, 
    { price: 250, percentage: "88%" }, 
    { price: 260, percentage: "90%" }   
  ];

  
 export const getPercentageByPrice = (price) => {
    if (price <= 70) return "2%";
    if (price >= 260) return "90%";
  
    for (let i = priceToPercentage.length - 1; i >= 0; i--) {
      if (price >= priceToPercentage[i].price) {
        return priceToPercentage[i].percentage;
      }
    }
  };
  