import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import SortingButton from "src/pages/EtherOrdersPage/components/EtherOrdersDataControl/components/SortingButton";
import { carClasses } from "src/shared/constants";
import { useTranslation } from "react-i18next";

const CarClassesFilter = ({
  carClassFilterButtonsSet,
  defaultCarClassId,
  activeCarClassIds,
  setActiveCarClassIds
}) => {
  const { t } = useTranslation();
  const [isActiveAllButton, setIsActiveAllButton] = useState(() => {
    const isActive = activeCarClassIds.size === carClassFilterButtonsSet.size;

    return isActive;
  });

  useEffect(() => {
    setIsActiveAllButton(activeCarClassIds.size === carClassFilterButtonsSet.size);
  }, [activeCarClassIds, carClassFilterButtonsSet]);


  const handleClickAllButton = () => {
    if (isActiveAllButton) {
      return;
    }
    const allClasses = Array.from(carClassFilterButtonsSet).map((button) => button.type);
    setActiveCarClassIds(new Set(allClasses));
    setIsActiveAllButton(true);
  };

  const handleCarClassFilter = (carClassId) => {
    setActiveCarClassIds((prevSet) => {
      const updatedSet = new Set(prevSet);

      if (updatedSet.has(carClassId) && updatedSet.size === 1) {
        return updatedSet;
      }

      if (updatedSet.has(carClassId)) {
        updatedSet.delete(carClassId);
      } else {
        updatedSet.add(carClassId);
      }

      return updatedSet;
    });
  };

  const filteredCarClassButtons = Array.from(carClassFilterButtonsSet).filter(
    (button) => !(defaultCarClassId === carClasses.COMFORT && button.type === carClasses.BUSINESS)
  );


  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <SortingButton
          active={isActiveAllButton}
          key="All"
          onClick={handleClickAllButton}
          size="filterStyle"
        >
          {t("all")}
        </SortingButton>
        {filteredCarClassButtons.map((buttonProps) => (
          <SortingButton
            key={buttonProps.type}
            active={activeCarClassIds.has(buttonProps.type)}
            onClick={() => handleCarClassFilter(buttonProps.type)}
            backgroundClass={buttonProps.backgroundClass}
            size="filterStyle"
          >
            {t(buttonProps.label)}
          </SortingButton>
        ))}
      </div>
    </div>
  );
};

export default CarClassesFilter;
