import { ReactComponent as ChildSeatIcon } from "src/images/сhild-seat.svg";
import { ReactComponent as CourierDeliveryIcon } from "src/images/courier-delivery.svg";
import { ReactComponent as AnimalTransportationIcon } from "src/images/animal-transportation.svg";
import { ReactComponent as JumpStartIcon } from "src/images/jump-start.svg";

export const iconsMap = {
  "child-seat": ChildSeatIcon,
  "courier-delivery": CourierDeliveryIcon,
  "animal-transportation": AnimalTransportationIcon,
  "jump-start": JumpStartIcon,
};

export const getIconByKey = (key) => {
  return iconsMap[key] || null;
};